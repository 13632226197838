<template>
  <div class="base-info">
    <div class="main">
      <div class="base-product">
        <span class="bold">{{ productInfo.productName }}</span>
        <span class="bold">¥{{ productInfo.salePrice }}</span>
      </div>
      <div class="product-tag">
        <span class="product-tag-item"
          >{{ productInfo.projectItemCount }}个检查项目</span
        >
        <span v-if="productInfo.projectDiseaseItems" class="product-tag-item"
          >筛查{{ productInfo.projectDiseaseItems.length }}个疾病</span
        >
      </div>

      <template v-if="projects && projects?.length">
        <div class="add-item-header">
          <span class="bold">套餐加项</span>
          <span class="bold">￥{{ addItemPrice }}</span>
        </div>
        <div class="add-item" v-for="item in projects" :key="item.projectNo">
          <span>{{ item.projectName }}</span>
          <!-- <span>¥{{ item.salePrice }}</span> -->
        </div>
      </template>
    </div>

    <div class="footer" v-if="showFooter">
      <div class="balance" v-if="showBalance">
        <span class="bold">企业团检金余额</span>
        <span class="bold">¥{{ balance }}</span>
      </div>

      <div
        class="footer-add-item"
        :style="{ marginTop: showBalance ? '5px' : '0' }"
        v-if="
          productInfo.hasAdditionPackageItems && !projects && !projects?.length
        ">
        <span>{{
          showBalance ? "未使用的团检金余额视为自动放弃" : "支持自选加项"
        }}</span>
        <span class="add-item-button" @click="jumpAddItem">立即加项</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    productInfo: {
      type: Object,
      default: () => {},
    },
    projects: {
      type: Array,
      default: () => [],
    },
    balance: {
      type: Number | String,
    },
    showBalance: {
      type: Boolean,
    },
    addItemPrice: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    getProjectItemCount() {
      if (this.$props.productInfo.portfolioItems) {
        let count = 0;
        this.$props.productInfo.portfolioItems.forEach((item) => {
          count += item.items.length;
        });
        return count;
      }
    },
    showFooter() {
      return (
        this.$props.showBalance ||
        (this.$props.productInfo.hasAdditionPackageItems &&
          !this.$props.projects &&
          !this.$props.projects?.length)
      );
    },
  },
  methods: {
    jumpAddItem() {
      this.$router.push("/add-item/" + this.$route.params.id);
    },
  },
};
</script>

<style scoped>
.base-info {
  background: url("../../assets/confirm-order-bg.png") 0 0 no-repeat;
  background-color: #fff;
  background-size: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.main {
  padding: 20px 10px 15px 10px;
}

.base-product {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  color: #333;
  line-height: 24px;
}

.product-tag {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}

.product-tag-item,
.product-keys-item {
  padding: 0 4px;
  margin-right: 4px;
  height: 20px;
  line-height: 20px;
  color: #0091ff;
  font-size: 12px;
  background: rgba(60, 196, 255, 0.1);
  border-radius: 4px;
}
.product-keys-item {
  color: #ffb470;
  background: rgba(255, 180, 112, 0.1);
}

.add-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  height: 28px;
  font-size: 15px;
  color: #333;
  font-weight: bold;
}

.add-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  color: #878d99;
}

.add-item:not(:first-child) {
  margin-top: 10px;
}

.footer {
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 400;
  color: #878d99;
  border-top: 1px solid #edeff0;
}

.footer-add-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.add-item-button {
  display: flex;
  align-items: center;
  padding: 0 13px;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  color: #00c4b3;
  background: rgba(0, 196, 179, 0.1);
  border: 1px solid #00c4b3;
  border-radius: 15px;
}

.balance {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  width: 100%;
  color: #333333;
}
</style>
