<template>
  <div class="form-container">
    <div class="item">
      <span>开票服务</span>
      <span style="color: #8c9196" @click="showInvoice = true"
        >{{ value === "1" ? "平台开票" : "医院开票"
        }}<van-icon style="margin-left: 16px" name="arrow-down"
      /></span>
      <van-action-sheet v-model="showInvoice" title="开票服务">
        <div class="content">
          <van-radio-group v-model="value" @change="onChange">
            <div class="custom-item">
              <van-radio name="1" checked-color="#00C4B3">
                <p class="custom-item-title bold">平台开票</p>
                <div class="desc">
                  提供平台开具的电子发票，开票类型“体检费”，可正常报销。在体检完成后到【个人中心-预约记录-预约详情】中申请开票。
                </div>
              </van-radio>
            </div>
            <div class="custom-item">
              <van-radio name="2" checked-color="#00C4B3">
                <p class="custom-item-title bold">医院开票</p>
                <div class="desc">
                  线上缴费不支持开医院发票，如需医院发票，
                  <span class="primary">请前往线下购买！</span>
                </div>
              </van-radio>
            </div>
          </van-radio-group>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
export default {
  props: ["invoiceType"],
  watch: {
    invoiceType(newValue) {
      this.value = newValue;
    },
  },
  data() {
    return {
      showInvoice: false,
      value: "1",
    };
  },
  methods: {
    onChange(name) {
      this.$emit("invoiceTypeChange", name);
      this.showInvoice = false
    },
  },
};
</script>

<style scoped >
.form-container {
  margin-top: 8px;
  background-color: #fff;
  border-radius: 8px;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 16px;
  font-size: 16px;
}
.content {
  padding: 16px 0;
}

.custom-item {
  margin: 15px;
  padding: 16px;
  border: 1px #e5e5e5 solid;
  border-radius: 8px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);

}

.custom-item-title {
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: bold;
}

.desc {
  line-height: 20px;
}
</style>