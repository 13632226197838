<template>
  <div class="footer-container">
    <div class="footer">
      <div class="agreement">
        <p style="color: #2c2f37">
          请阅读并知晓
          <span
            style="color: #00c4b3"
            @click.stop="
              showPopup = true;
              manualType = 'service';
            "
            >《用户服务协议》</span
          >
          <span
            style="color: #00c4b3"
            @click.stop="
              showPopup = true;
              manualType = 'auth';
            "
            >《用户授权协议》</span
          >
        </p>
        <van-checkbox
          v-model="value"
          checked-color="#00c4b3"
          @change="needKownChange"
        >
        </van-checkbox>
      </div>

      <div class="footer-buy">
        <div>
          <div class="footer-price">
            <span>{{ payeeCompany ? '线下付款：':'实付款：' }}</span>
            <span class="red bold" style="font-size: 14px">￥</span>
            <span class="red bold" style="font-size: 20px">{{
              totalPrice
            }}</span>
          </div>
          <div style="margin-top: 2px" @click="lookDetail">
            <span class="discount" v-if="discount">已抵扣¥{{ discount }}</span>
            <span class="look-detail">
              查看明细
              <img src="@/assets/icon-right-primary.png" alt="" />
            </span>
          </div>
        </div>
        <van-button
          style="width: 150px; height: 40px"
          round
          color="#00c4b3"
          @click="submitOrder"
          :disabled="invoiceType === '2'"
          >{{ payeeCompany ? '立即预约':'支付并预约' }}</van-button
        >
      </div>
    </div>

    <rule
      :show="showPopup"
      :type="manualType"
      @close="showPopup = false"
    ></rule>
  </div>
</template>

<script>
import manual from "@/views/manual";
import rule from "@/components/rule.vue";
export default {
  components: {
    manual,
    rule,
  },
  props: {
    totalPrice: {
      type: Number | String,
    },
    invoiceType: {
      type: String,
    },
    discount: {
      type: Number | String,
    },
    needKown: {
      type: Boolean,
    },
    payeeCompany: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    needKown(newVal) {
      this.value = newVal;
    },
  },
  data() {
    return {
      value: false,
      goodDetail: {},
      showPopup: false,
      manualType: "",
    };
  },
  methods: {
    submitOrder() {
      this.$emit("submitOrder");
    },
    lookDetail() {
      this.$emit("lookDiscount");
    },
    needKownChange(checked) {
      this.$emit("needKownChange", checked);
    },
  },
};
</script>

<style scoped>
.footer-container {
  height: 120px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9;
}

.agreement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 35px;
  box-shadow: 0px -4px 5px 0px rgba(0, 0, 0, 0.04);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
.footer-buy {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 50px;
}
.footer-buy::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #edeeef;
  height: 1px;
  width: 100%;
  transform: scaleY(0.5);
}

.footer-price {
  font-size: 14px;
  color: #2c2f37;
  margin-right: 5px;
}

.discount {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #878d99;
}

.look-detail {
  font-size: 12px;
  font-weight: 400;
  color: #00c4b3;
}

.look-detail img {
  width: 8px;
  height: 8px;
  transform: rotate(-90deg);
}
</style>